<script lang="ts" setup>
defineProps<{
  lightJade?: boolean
  jade?: boolean
  indigo?: boolean
  classStr?: string
}>()
</script>

<template>
  <div
    class="absolute bottom-0 top-0 w-full transition-colors"
    :class="[
      classStr,
      {
        'bg-jade-50/50': lightJade,
        'bg-jade-50': jade,
        'bg-[#f5f5ff]': indigo,
      },
    ]"
  >
    <div class="absolute bottom-[-54px] left-0 right-0 h-[55px] overflow-hidden">
      <svg
        width="1760"
        height="55"
        viewBox="0 0 1760 55"
        fill="none"
        class="absolute bottom-0 left-[50%] min-w-[100vw] translate-x-[-50%] transform"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M476.713 50.9517C635.088 61.9926 829.682 46.9502 829.682 46.9502C829.682 46.9502 1030.47 25.6262 1167 28.7466C1233.75 30.2717 1551.11 46.6863 1915 46.9502C2182.66 47.1444 2463.65 23.0451 2701 22.6461C2964.95 22.2023 3171.08 23.7248 3186 22.6461L3186 0.275124H-1426V22.646C-1395.33 20.3273 -1093.06 51.179 -737.995 49.937C-533.58 49.222 -450.668 18.41 -253 18.2885C59.3806 18.0965 292 31.3612 476.713 50.9517Z"
          class="transition-colors"
          :class="{
            'fill-jade-50/50': lightJade,
            'fill-jade-50': jade,
            'fill-[#f5f5ff]': indigo,
          }"
        />
      </svg>
    </div>
  </div>
</template>
